import React from "react"
import styled from "styled-components"
import { H1, H2, H3, H4 } from "../styles/TextStyles"

const RulesTitle = props => {
  console.log("PROPS ", props)
  return (
    <Wrapper area={props.area}>
      <ContentWrapper>
        {props.type === "H1" ? (
          <TitleTextH1>
            NORVINA Cyberpunk Stunt Sweepstakes OFFICIAL RULES
          </TitleTextH1>
        ) : (
          <TitleTextH4>
            NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. A
            PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING.
          </TitleTextH4>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

export default RulesTitle

const Wrapper = styled.div`
  grid-area: ${props => props.area};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1680px;
  width: 100%;
`

const ContentWrapper = styled.div`
  max-width: 946px;
  width: 100%;
  overflow: hidden;
  margin: 2rem 0;

  @media (max-width: 1024px) {
    max-width: 800px;
    width: 80%;
    margin: 0rem 0;
  }
  @media (max-width: 550px) {
    max-width: 450px;
    width: 60%;
    margin: 0rem 0;
  }
`

const TitleTextH1 = styled(H1)`
  color: #e616ff;
`
const TitleTextH4 = styled(H4)`
  color: #e616ff;
`
