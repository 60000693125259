import React from "react"
import styled from "styled-components"
import { BodyMain } from "../styles/TextStyles"

const RulesBody = props => {
  return (
    <Wrapper area={props.area}>
      <ContentWrapper>
        <BodyText>
          1. Overview: The Norvina Cyberpunk Stunt Sweepstakes (the
          ”Sweepstakes”) comprises a series of four (4) separate weekly drawings
          as set forth below. By participating in the Sweepstakes, you agree to
          these Official Rules and all decisions of the judges, which are final
          and binding in all respects.
        </BodyText>
        <br />
        <BodyText>
          2. Eligibility: Only legal residents of the 50 United States and DC,
          who are 18 or older, may enter. Employees and directors of Sponsor or
          any of its parents, subsidiaries and affiliates, advertising,
          promotion, or production agencies (and their respective IRS
          dependents, immediate family members and individuals residing in their
          same household) are not eligible to participate. Void where prohibited
          by law. All applicable federal, state and local laws apply.
        </BodyText>
        <br />
        <BodyText>
          3. Sweepstake Period: The Sweepstakes begins at 12:00:01 a.m. PT on
          June 7, 2021, and ends at 11:59:59 p.m. PT on July 4, 2021 (the
          “Sweepstakes Period”). The Sponsor’s database computer is the official
          timekeeping device for the Sweepstakes.
        </BodyText>
        <br />
        <BodyText>
          4. How to Enter: Visit www.norvina.com (the “Site”) to play “Norvina’s
          Cyberpunk Stunt” free online game (the “Game”). The Game involves
          flying through a virtual futuristic alley collecting as many colored
          hearts (25 in total) as possible while avoiding red holograms.
          Gameplay continues until you either collect all the hearts or run out
          of lives from colliding with holograms. Performance in the Game is
          just for fun and doesn’t affect winning in the Sweepstakes. You may
          play as many times as you wish, but you may only enter the Sweepstakes
          one (1) time per day (measured by PT). To enter, simply follow the
          prompts to furnish your first name, last name and email address at the
          end of any round of play in the Game. Limit one (1) entry per person
          and per email address per day. Any attempt to enter more than the
          allowed number of times by use of multiple identities, email
          addresses, or other means is grounds for disqualification. Incomplete
          submissions are void. All entries must be received by the end of the
          Sweepstakes Period. Proof of transmission is not proof of receipt. Use
          of any macro, bot, script or other automated means to submit entries
          is prohibited. Each entry will be deemed submitted by the authorized
          account holder of the email address submitted with the entry. An
          “authorized account holder” is defined as the natural person to whom
          the email address is assigned by an internet service provider, online
          service provider, or other organization that is responsible for
          assigning email addresses for the domain of the email address
          associated with the social media account used to enter.
        </BodyText>
        <br />
        <BodyText>
          5. Winner Selection/Notification: A series of four (4) separate
          drawings will be held on the following dates: June 14, June 21, June
          28, July 5 Each drawing will be from among the eligible entries
          received by 11:59:59 p.m. PT the day prior to the scheduled drawing.
          Winners are determined by the random drawings, not by performance or
          score in the Game. Non-winning entries in any drawing remain eligible
          any subsequent drawings in the Sweepstakes. Each potential winner will
          be notified promptly after the relevant drawing via the email address
          submitted with the entry. Each potential winner must respond to the
          initial notification within 72 hours or prize will be forfeited and
          may be awarded to an alternate winner. Failure to comply when
          requested may result in disqualification and selection of an alternate
          winner. If a potential winner is found to be ineligible, or if he or
          she has not complied with these Official Rules or declines a prize for
          any reason prior to award, such potential winner will be disqualified
          and an alternate winner may be selected.
        </BodyText>
        <br />
        <BodyText>
          6. Prizes/Odds: The following different sets of prizes are available
          to be awarded in the respective drawings:{" "}
        </BodyText>
        <br />
        <BodyText>Drawing 1 – June 14 </BodyText>
        <BodyText>
          A Norvina Bucket Hat &#38; Tote bag (450 available) – approx. ret.
          val. (ARV) $20
        </BodyText>
        <br />
        <BodyText>Drawing 2 – June 21 </BodyText>
        <BodyText>
          A Norvina Bucket Hat &#38; Tote bag (450 available) – approx. ret.
          val. (ARV) $20
        </BodyText>
        <br />
        <BodyText>Drawing 3 – June 28</BodyText>
        <BodyText>A Norvina T-Shirt (185 available) – ARV $13 </BodyText>
        <BodyText>A Norvina Hoodie (370 available) – ARV $25</BodyText>
        <br />
        <BodyText>Drawing 4 – July 5</BodyText>
        <BodyText>A Norvina T-Shirt (185 available) – ARV $13 </BodyText>
        <BodyText>A Norvina Hoodie (370 available) – ARV $25</BodyText>
        <br />
        <BodyText>
          Sizes and colors of items are subject to availability in Sponsor’s
          discretion. Limit (1) prize per drawing to any person, regardless of
          number of entries. If fewer entries are received for a drawing than
          the number of available prizes for that drawing, fewer than all prizes
          may be awarded for that drawing, and any excess prizes will be carried
          forward to the next drawing, if any. Odds in any drawing depend on the
          number of eligible entries received for that drawing. General Prize
          Conditions: Prizes are non-transferable and no cash equivalent or
          substitution is offered, except at the sole discretion of the Sponsor.
          Sponsor may substitute a prize of comparable value if any advertised
          prize becomes unavailable. Allow 4-6 weeks after validation for
          delivery or arrangement for delivery of prize. Each potential winner
          (a) is responsible for all federal, state and/or local taxes imposed
          as a result of acceptance of the prize and for all costs and expenses
          not identified above and (b) may be required to execute an Affidavit
          of Eligibility, a Liability Release, and (where legal) a Publicity
          Release (!Prize Claim Documents”). If potential eligible winner is not
          of legal age of majority in his or her state of residence, at
          Sponsor"s option, the prize either may be awarded in the name of
          parent or legal guardian of winner, or parent or legal guardian of
          winner may be required to ratify and sign all Prize Claim Documents.
        </BodyText>
        <br />
        <BodyText>
          7. Additional Rules: Entry materials/data that have been tampered with
          or altered are void. No responsibility is assumed for: (i) lost, late,
          misdirected, damaged, garbled, incomplete or illegible entries, all of
          which are void; (ii) error, omission, interruption, deletion, defect,
          delay in operations or transmission, theft or destruction or
          unauthorized access to or alterations of entry materials, or for
          technical, network, telephone equipment, electronic, mobile device,
          computer, hardware or software malfunctions of any kind, or inaccurate
          transmission of or failure to receive entry information by Sponsor on
          account of technical problems; or (iii) any injury or damage to
          entrant's or any other person's mobile device or computer related to
          or resulting from participating in the Sweepstakes or the Game.
          Sponsor reserves the right to: (i) permanently disqualify from any
          promotion it sponsors any person it believes has intentionally
          violated these Official Rules; and (ii) suspend, modify or terminate
          any drawing (or the entire Sweepstakes) if Sponsor believes, in its
          sole discretion, that malfunction, error, disruption or damage is
          impairing or will impair the administration, security, fairness,
          integrity or proper conduct of the drawing or Sweepstakes. In such
          circumstances, Sponsor will endeavor, to the extent reasonably
          possible, to award the prizes for the affected drawing(s) in a fair
          and equitable manner, as determined by Sponsor in its discretion,
          among any eligible entries received for the applicable drawing(s) that
          were unaffected by the problem.
        </BodyText>
        <br />
        <BodyText>
          8. Publicity/Usage Rights: Except where prohibited, participation in
          the Sweepstakes and/or receipt or use of any prize constitutes the
          winner"s consent for use of his/her name, likeness, voice, opinions,
          statements, biographical information, and/or hometown and state for
          promotional or advertising purposes in any media, worldwide, now known
          or hereafter developed, in perpetuity, without further review,
          notification, payment, or consideration (unless prohibited by law).
        </BodyText>
        <br />
        <BodyText>
          9. Release of Liability: By participating, you release Sponsor, and
          its parent companies, subsidiaries, affiliates, partners, employees,
          directors, agents, advertising agencies, and all other associated with
          the development and execution of the Game and/or the Sweepstakes from
          any and against any and all injury, loss or damage caused or claimed
          to be caused by your participation in the Game or the Sweepstakes and
          the acceptance, awarding, receipt, use and/or misuse of any prize, and
          you agree that these entities are not responsible for any warranty,
          representation, or guarantee, expressed or implied, in fact or in law,
          relating to the Sweepstakes or any prize(s) except as expressly
          provided in these Official Rules. By participating, you further agree
          that: (a) any and all disputes, claims, and causes of action arising
          out of or in connection with any Giveaway, or any prizes awarded,
          shall be resolved individually without resort to any form of class
          action; (b) any claims, judgments and awards shall be limited to
          actual out of-pocket costs incurred, including costs associated with
          entering any Giveaway, but in no event attorney"s fees; (c) under no
          circumstances will any entrant be permitted to obtain any award for,
          and entrant hereby waives all rights to claim, punitive, incidental or
          consequential damages and any and all rights to have damages
          multiplied or otherwise increased and any other damages, other than
          damages for actual out-of-pocket expenses; and (d) these Official
          Rules and all disputes shall be governed by the internal laws of the
          state of California, without regard to principles of conflicts of
          laws, any disputes shall be resolved exclusively in the state or
          federal courts in the state of California, and you expressly consent
          to the jurisdiction of said courts and waive any objection thereto.
        </BodyText>
        <br />
        <BodyText>
          10. Data: Any information you submit is subject to Sponsor’s privacy
          policy available at
          https://www.anastasiabeverlyhills.com/privacy-policy.html
        </BodyText>
        <br />
        <BodyText>
          11. Winner Names: For the names of the winners in any drawing,
          available approximately two weeks after the end of the Sweepstakes
          Period, send a self addressed stamped envelope to: Anastasia Beverly
          Hills, LLC, 10635 Santa Monica Blvd. Ste 300 Los Angeles CA 90025. Be
          sure to specify the drawing date you are asking about. All such
          requests must be received within 60 days after the Sweepstakes Period.
        </BodyText>
        <br />
        <BodyText>
          Sponsor: Anastasia Beverly Hills, LLC, 10635 Santa Monica Blvd. Ste
          300 Los Angeles CA 90025.
        </BodyText>
      </ContentWrapper>
    </Wrapper>
  )
}

export default RulesBody

const Wrapper = styled.div`
  grid-area: ${props => props.area};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1680px;
  width: 100%;
  margin: 2rem 0;
`

const ContentWrapper = styled.div`
  max-width: 946px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 80%;
    max-width: 800px;
    margin: 2rem 2rem;
  }
  @media (max-width: 550px) {
    width: 60%;
    max-width: 550px;
    margin: 2rem 2rem;
  }
`

const BodyText = styled(BodyMain)`
  color: #c4b3e2;
`
