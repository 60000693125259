import React from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import Footer from "../components/sections/Footer"
import RulesBody from "../components/rulesBody/RulesBody"
import RulesTitle from "../components/rulesTitle/rulesTitle"
import Nav from "../components/sections/Nav"

function RulesPage() {
  return (
    <Layout>
      <Seo title="Official Rules" />
      <Nav />
      <RulesTitle
        title="NORVINA
        Cyberpunk Stunt Sweepstakes
        OFFICIAL RULES
        "
        area="ticker1"
        type="H1"
      />
      <RulesTitle
        title="NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN.  A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING."
        area="game"
        type="H4"
      />
      <RulesBody area="gamelegal" />

      <Footer show={true} />
    </Layout>
  )
}

export default RulesPage
